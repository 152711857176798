import React from "react";
import {IconType} from "@onemind-services-llc/react-icons-ng";
import {Center, Heading, Icon, Stack} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";

type Props = {
  listItems: {content: string; icon: IconType}[];
  isDefault?: boolean;
};

const ListWithIcon = (props: Props) => {
  const {listItems, isDefault = true} = props;
  return (
    <Stack spacing={6}>
      {listItems.map((item, index) => {
        const {content, icon: FeatureIcon} = item;
        const isEven = index % 2 == 0;
        return (
          <Stack
            alignItems="center"
            direction={{base: "column", sm: isDefault ? "row" : "row-reverse"}}
            justifyContent={isDefault ? "start" : "end"}
            key={index}
            spacing={4}
          >
            <Center
              bg={isEven ? "brand.100" : "black"}
              borderRadius="50%"
              h="50px"
              minH={"50px"}
              minW={"50px"}
              w="50px"
            >
              <Icon as={FeatureIcon} color="white" fontSize={"25px"} />
            </Center>
            <Heading
              textAlign={{base: "center", sm: isDefault ? "start" : "end"}}
              variant={HEADING_VARIANTS.SUBTITLE4}
            >
              {content}
            </Heading>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ListWithIcon;
