import Image from "next/image";
import {IconType} from "@onemind-services-llc/react-icons-ng";
import {AspectRatio, SimpleGrid, Stack} from "@chakra-ui/react";
import ListWithIcon from "@/components/listWithIcon";

type ListType = {content: string; icon: IconType}[];
type Props = {
  image: string;
  list1: ListType;
  list2: ListType;
};

const ListLayout = (props: Props) => {
  const {image, list1, list2} = props;
  return (
    <SimpleGrid columns={{base: 1, sm: 2, md: 3}} spacing={{base: 6, sm: 8}}>
      <ListWithIcon listItems={list1} isDefault={false} />
      <Stack display={{base: "none", md: "flex"}} justifyContent={"center"}>
        <AspectRatio position={"relative"} ratio={1} width="full">
          <Image
            alt=""
            fill
            src={image}
            style={{objectFit: "contain", objectPosition: "center"}}
          />
        </AspectRatio>
      </Stack>
      <ListWithIcon listItems={list2} />
    </SimpleGrid>
  );
};

export default ListLayout;
